// General Style
body {
  background: #fff;
  padding: 0;
  margin: 0;
}

.form-controlcal{
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}
.p-button{
 margin-top: unset !important;
}

.pp-passcode{
  font-size: 18px;
    background-color: #496659;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}

.btn-get-pass {
  background-color: #009f7c;
  border: 1px solid #009f7c;
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;  
}

.datatable-filter-demo .table-header {
  display: flex;
  justify-content: space-between;
}

.datatable-filter-demo .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.p-input-icon-left{
  float:right;
}

* {
  font-family: "DroidKufi-Regular";
  text-decoration: none;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #53b196;
  }

  &::-webkit-scrollbar-track {
    background: #ececec;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: #53b196;
    scrollbar-track-color: #ececec;
  }
}

p{
  margin:0 !important
}

.p-carousel-indicators {
  display: none !important;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus,
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
  box-shadow: none !important;
}

input.form-control:focus,
textarea.form-control:focus {
  border-color: #6bc5b2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #28a7452e;
  font-size: 14px;
}

.climp{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.num2{
    -webkit-line-clamp: 2;
  }
  &.num3{
    -webkit-line-clamp: 3;
  }
  &.num4{
    -webkit-line-clamp: 4;
  }
  &.num5{
    -webkit-line-clamp: 5;
  }
  &.num6{
    -webkit-line-clamp: 6;
  }
}

.text-justify{
  text-align: justify;
}

// Main Header
.main-header {
  @media screen and (min-width: 992px) {
    height: 140px;
  }
  @media screen and (max-width: 991px) {
    height: 145px;
  }
  @media screen and (max-width: 767px) {
    height: 178px;
  }

  .topbar {
    display: flex;
    justify-content: space-between;
    background-color: #00b188;
    color: #fff;

    ul {
      display: flex;
      margin-bottom: 0;
      li {
        padding: 5px 25px;
        text-align: center;
        a {
          color: #fff;
        }
      }
    }

    .left {
      ul {
        li {
          padding: 0;
          width: 40px;
          height: 36px;
          border-left: 1px solid #17826b;

          a {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 20px;
            display: inline-block;
            width: 40px;
            height: 36px;

           
          }

          &:hover {
            a {
              background-color: #fff;
             
            }
          }
        }
      }
    }

    .right {
      ul {
        li {
          border-right: 1px solid #17826b;

          &:first-child {
            border-right: none;
          }
          a {
            text-decoration: none;
            font-size: 13px;
          }
        }
      }

      .dropdown-toggle {
        background: none;
        border: none;
        padding: 0;
        font-size: 13px;
        box-shadow: none;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }
  }

  .header-items {
    background-color: #f1f1f3;
    // background-image: url("../images/icons/honorBG.svg");
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: center center;

    .container {
      padding: 10px 0;

      .navbar {
        padding: 0;

        .navbar-brand {
          img {
            width: 250px;
          }
        }

        .navbar-nav {
          padding: 0 50px;
          padding-left: 0;
          background-image: url("../images/icons/logo2.svg"); 
          background-repeat: no-repeat;
          background-position: 3% 17%;
          background-size: 180px;
          padding-top: 100px;
          li {
            margin: 0 9px;
            &:last-child {
              margin-right: 15px;
            }

            .nav-link {
              color: #fff;
              font-size: 17px;
              font-family: "DroidKufi-bold";
              cursor: pointer;
              &:hover {
                color: #00b188;
              }
              &.active {
                color: #00b188;
              }

              &:after{
                margin-right: 6px;
                margin-bottom: -6px;
              }
            }

            .nav-link[aria-expanded="true"] {
              color: #00b188;
            }

            .dropdown-menu {
              min-width: 11rem;
              border-top: 5px solid #00b188;

              .dropdown-item {
                border-bottom: 1px solid #f7f7f7;
                font-size: 14px;
                color: #2d2d34;
                padding: 8px;
                transition: all 0.25s ease-in;
                &:last-child {
                  border-bottom: none;
                }

                &:hover {
                  background-color: #00b188;
                  color: #fff;
                }

                &.active {
                  color: #fff;
                  background-color: #00b188;
                }
              }
            }
          }
        }

        .navbar-toggle {
          display: none;
          border: none;
          background: transparent !important;
          margin-left: 15px;
          &:hover {
            background: transparent !important;
          }

          .icon-bar {
            width: 22px;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            display: block;
            height: 1px;
            background: #fff;
            margin-bottom: 5px;

            &:nth-of-type(1) {
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-transform-origin: 10% 10%;
              -ms-transform-origin: 10% 10%;
              transform-origin: 10% 10%;
            }

            &:nth-of-type(2) {
              opacity: 0;
              filter: alpha(opacity=0);
            }

            &:nth-of-type(3) {
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
              -webkit-transform-origin: 10% 90%;
              -ms-transform-origin: 10% 90%;
              transform-origin: 10% 90%;
            }
          }

          &.collapsed {
            .icon-bar {
              &:nth-of-type(1) {
                -webkit-transform: rotate(0);
                -ms-transform: rotate(0);
                transform: rotate(0);
              }

              &:nth-of-type(2) {
                opacity: 1;
                filter: alpha(opacity=100);
              }

              &:nth-of-type(3) {
                -webkit-transform: rotate(0);
                -ms-transform: rotate(0);
                transform: rotate(0);
              }
            }
          }
        }
      }
    }
  }

  &.homeHeader {
    height: auto;
    .header-items {
      position: absolute;
      top: 36px;
      left: 0;
      width: 100%;
      z-index: 9999;
      background-color: transparent;
      background-image: none;
    }

    .fixed{
      .header-items {
        // background-image: url("../images/icons/honorBG.svg");
      }
    }
  }

  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .header-items {
      border-bottom: 5px solid #009f7c;
      background-color: #474748;
    }

    .container {
      padding: 0;
      .navbar {
        .navbar-brand {
          img {
            width: 120px;
          }
        }
        .navbar-nav {
          padding-top: 10px;
          background-image: none;
        }
      }
    }
  }
}

// Main Footer
.main-footer {
  .topfooter {
    background-color: #2d2d34;
    padding: 35px 0;

    .social {
      h4 {
        margin-top: 40px;
        margin-bottom: 15px;
        color: #fff;
        font-size: 12px;
      }

      a {
        padding-right: 20px;
      }
    }

    .contacts {
      h4 {
        font-size: 22px;
        color: #707070;
      }

      ul {
        li {
          color: #fff;
          margin-bottom: 8px;
          font-size: 14px;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

          img {
            margin-left: 10px;
            width: 15px;
          }
        }
      }
    }

    .links {
      h4 {
        font-size: 22px;
        color: #707070;
      }

      ul {
        display: inline-block;
        float: left;
        margin-right: 25px;
        li {
          margin-bottom: 8px;
          a {
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }

    .copyright {
      text-align: center;
      color: #707070;
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 0;
    }
  }

  .bottomfooter {
    padding: 5px 15px;
    display: flex;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ddd;
    z-index: 99;

    img {
      margin: 0 20px;
      width:32px;
    }

    .footer-news {
      width: 90%;
      padding-top: 15px;
    }

    a.btn {
      color: #d10028;
      background-color: #fff;
      border-radius: 8px;
      border-color: #ced0d1;
      font-size: 14px;
      display: flex;
      align-items: center;
      min-width: 130px;
      justify-content: center;
    }

    .closeBTN {
      font-size: 35px;
      margin-left: 25px;
      background: none;
      border: none;
      padding: 0;
      color: #000;
    }
  }
}

// General Syles
.banner {
  
  background-position: center 15%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  text-align: center;
  position: relative;

  .overlay {
    background-color: rgba(0, 159, 124, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 20px 0;

    h3 {
      font-size: 70px;
      font-family: "DroidKufi-bold";
      color: rgba(255, 255, 255, 0.3);
      margin-bottom: 20px;
    }

    h5 {
      font-size: 40px;
      color: #fff;
      font-family: "DroidKufi-bold";
    }
  }
}

.page-with-sidebar {
  display: flex;
  .sidebar {
    width: 350px;
    min-height: 500px;
    background-color: #f7f7f5;
    padding: 30px;
    align-items: stretch;

    .latestnews {
      margin-bottom: 30px;
      direction: ltr;
      text-align: center;
      background-color: #fff;
      position: relative;
      padding: 20px 0;
      border-bottom: 4px solid #009f7c;

      .title {
        text-align: center;
        padding-bottom: 65px;
        border-bottom: 1px solid #eee;
        color: #2d2d34;
        font-size: 22px;
        margin-bottom: 20px;
      }

      .p-carousel-item {
        width: 100%;
      }

      button {
        &.p-link {
          position: absolute;
          top: 60px;
          border: 1px solid #009f7c;
          span {
            color: #009f7c;
            background: transparent;
            padding: 10px 15px;
            font-weight: bold;
          }

          &.p-carousel-prev {
            left: 30%;
          }
          &.p-carousel-next {
            left: 50%;
          }
        }
      }

      .p-carousel-indicators {
        display: none;
      }

      .p-items-hidden .p-carousel-item {
        visibility: visible;
      }

      .news-item-content {
        padding: 0 30px;
        .img {
          img {
            width: 80%;
            margin: auto;
            height: 150px;
          }
        }

        h3 {
          color: #000;
          font-size: 14px;
          margin-top: 15px;
          text-align: right;
        }
      }
    }

    .latest-matches {
      margin-bottom: 30px;
      height: 300px;
      border-radius: 15px;
      background-color: #046c4d;
      background-image: linear-gradient(to bottom, #046c4d, rgba(255, 255, 255, 0.5));
      padding: 20px;

      .title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        padding-bottom: 10px;
        h3 {
          color: #fff;
          font-size: 22px;
          img {
            margin-left: 10px;
            width: 35px;
          }
        }

        h5 {
          color: #fff;
          font-size: 12px;
        }
      }

      .box-body {
        margin-top: 40px;

        .score {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          .middle {
            width: 100px;
            font-size: 22px;
            text-align: center;
            span {
              color: #fff;
              font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
              unicode-bidi: plaintext;
            }
          }

          .left,
          .right {
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            color: #fff;
            font-size: 10px;
            border-radius: 12px;
            background-color: rgba(0, 0, 0, 0.1);
            padding: 10px;
            img {
              max-width: 100%;
              margin-bottom: 5px;
            }
          }
        }
        .date-location {
          display: flex;
          justify-content: space-around;

          .location,
          .date {
            color: #fff;
            font-size: 10px;

            img {
              margin: 0 8px;
            }
          }

          .date {
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          }
        }
      }
    }

    .adds {
      margin-bottom: 30px;
      img {
        width: 100%;
      }
    }
  }

  .content {
    width: calc(100% - 350px);
    padding: 30px 20px;
  }
}

// Home Page
.homepage {
  //Main Slider Section
  .mainSLider {
    height: calc(100vh - 81px);
    .slider-wrapper {
      height: 100%;

      .inner {
        display: flex;
        padding: 0;
        padding-top: 50px;
        .left {
          width: 60%;
          padding: 5%;
          margin-top: 6%;
          h3 {
            color: #f8c83b;
            font-family: "DroidKufi-Bold";
          }

          p {
            margin-bottom: 0;
            width: auto;
            max-width: initial;
            margin: 0;

            &.meta-date {
              font-size: 13px;
              color: #bbb6a5;
              margin-bottom: 10px;
            }

            &.description {
              font-size: 16px;
              line-height: 24px;
              color: #fff;
            }
          }
        }

        .right {
          width: 40%;
          height: 300px;
          margin-top: 8%;
          background: transparent;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 0 58px 40px 0;
            clip-path: polygon(
              0% 2%,
              0% 80%,
              81% 96%,
              83% 96%,
              85% 95%,
              86% 94%,
              87% 91%,
              97% 9%,
              97% 7%,
              96% 4%,
              94% 2%
            );
          }

          button {
            position: absolute;
            bottom: 0px;
            right: 115px;
            z-index: 9999;
            background: #bbb6a5;
            width: 120px;
            color: #fff;
            border: 1px solid #707070;
          }
        }
      }
    }
  }

  // NewsTiceker Section
  .newsticker {
    height: 45px;
    direction: ltr;
    position: relative;
    padding: 0 160px;
    background-color: #bbb6a5;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid #a49999;
    img {
      width: 150px;
      position: absolute;
      right: 0;
      top: -10px;
    }

    .p-carousel-indicators {
      display: none;
    }

    button {
      &.p-link {
        position: absolute;
        top: 0;
        background-color: #bbb6a5;
        border: 1px solid #707070;
        span {
          color: #000;
          background: transparent;
          padding: 13px;
          font-weight: bold;
        }

        &.p-carousel-prev {
          left: 0px;
        }
        &.p-carousel-next {
          left: 44px;
        }
      }
    }

    h3 {
      font-size: 22px;
      padding: 8px;
      margin: 0 15px;
    }

    .newsTicker-item {
      .newsTicker-item-content {
        p {
          margin-bottom: 0;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          padding-top: 10px;
          margin: 0 15px;
          span {
            &:first-child {
              width: 100%;
            }
            &:last-child {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  
  // President Word Section
  .presidentWord {
    
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border: 1px solid #adb5bd;
    .overlay {
      padding: 50px 0;
      padding-bottom: 60px;
      background-color: rgba(255, 255, 255, 0.75);
    }

    .container {
      display: flex;

      .left {
        position: relative;
        img {
          border: 4px solid #00b188;
        }

        &:before {
          content: "";
          position: absolute;
          left: -35px;
          top: 0px;
          height: 120%;
          width: 3px;
          background-color: #00b188ad;
          display: block;
        }

        &:after {
          content: "";
          position: absolute;
          left: -66px;
          bottom: -25px;
          height: 3px;
          width: 650px;
          background-color: #00b188ad;
          display: block;
        }
      }

      .right {
        padding: 0 35px;
        position: relative;
        h3 {
          color: #00b188;
          margin-top: -10px;
          margin-bottom: 35px;
        }

        p {
          font-size: 22px;
          color: #2d2d34;
        }

        &:before {
          content: "";
          position: absolute;
          right: -35px;
          top: -30px;
          height: 120%;
          width: 3px;
          background-color: #00b188ad;
          display: block;
        }

        &:after {
          content: "";
          position: absolute;
          right: -66px;
          top: 3px;
          height: 3px;
          width: 600px;
          background-color: #00b188ad;
          display: block;
        }
      }
    }
  }



  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    70% {
      -webkit-box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.3);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  @keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    }
    70% {
      -webkit-box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.3);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

 

  // Events and Competitons Section
  .events-competitions {
    .container {
      margin-top: 20px;
      padding: 30px 0;
      border-top: 3px solid #f7f7f7;
    }

    .readMore {
      text-align: left;
      padding: 10px;
      a {
        color: #000;
        font-size: 14px;
      }
    }

    .events {
      direction: ltr;
      position: relative;
      .title {
        h3 {
          color: #000;
          font-size: 25px;
          font-family: "DroidKufi-Bold";
          img {
            margin-left: 10px;
            width: 35px;
          }
        }

        h5 {
          color: #2d2d34;
          font-size: 15px;
        }
      }

      button {
        &.p-link {
          position: absolute;
          top: 0;
          span {
            color: #000;
            background: #f8f8f8;
            padding: 15px;
            font-weight: bold;
          }

          &.p-carousel-prev {
            left: 0px;
          }
          &.p-carousel-next {
            left: 50px;
          }
        }
      }

      .p-carousel-items-content {
        height: 500px !important;
        .p-carousel-item {
          padding: 15px 0;
        }
        .event-item {
          background-color: #f8f8f8;

          .event-item-content {
            .left {
              float: left;
              display: inline-block;
              padding: 10px;
              img {
                width: 150px;
                height: 120px;
              }
            }

            .right {
              float: left;
              display: inline-block;
              width: calc(100% - 170px);

              .shortDate {
                width: 65px;
                padding: 5px 0;
                border-radius: 3px 0 0 3px;
                text-align: center;
                display: inline-block;
                font-size: 16px;
                line-height: 18px;
                // font-weight: bold;
                color: #fff;
                background-color: #00b188;
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                small {
                  display: block;
                  font-size: 100%;
                  font-family: 'DroidKufi-Regular';
                }
              }
              h3 {
                padding: 5px 15px;
                color: #000;
                font-size: 16px;
                line-height: 25px;
                font-family: "DroidKufi-Regular";
                min-height: 50px;
                margin: 0;
              }

              p {
                direction: rtl;
                span {
                  padding: 0 10px;
                  color: #00b188;
                  font-size: 16px;
                  font-weight: bold;
                  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                }
              }
            }
          }
        }
      }

      .p-items-hidden .p-carousel-item {
        visibility: visible;
      }
    }

    .competitions {
      .title {
        h3 {
          color: #000;
          font-size: 25px;
          font-family: "DroidKufi-Bold";
          img {
            margin-left: 10px;
            width: 35px;
          }
        }
      }

      .comps {
        background-color: #f8f8f8;
        border-radius: 10px;
        padding: 15px 5px;
        margin-bottom: 25px;
        margin-top: 40px;
        // ul {
        //   li {
        //     float: left;
        //     margin: 0 12px;
        //     display: flex;
        //     flex-direction: column;
        //     background-color: #c6c0b2;
        //     color: #707070;
        //     font-size: 12px;
        //     justify-content: center;
        //     text-align: center;
        //     width: 100px;
        //     height: 100px;
        //     border-radius: 10px;
        //     cursor: pointer;
        //     img {
        //       width: 24px;
        //       margin-bottom: 0px;
        //       margin: 5px auto;
        //     }
        //   }
        // }
        .sports {
          // margin-top: 30px;
    
          .sport-item {
            background-color: #fff;
            height: 190px;
            width: 120px;
            border-radius: 100px;
            text-align: center;
            padding: 16px 0;
            cursor: pointer;
            // box-shadow: 0px 4px 26px 3px rgba(0, 0, 0, 0.1);
            // margin: 20px 0;
    
            .icon {
              background-color: #009f7c;
              width: 100px;
              margin: auto;
              height: 100px;
              border-radius: 50%;
              padding: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
    
              img {
                width: 60px;
                max-width: 100%;
              }
            }
    
            h6 {
              margin-top:10px;
              color: #009f7c;
              font-size: 15px;
              padding: 0 5px;
            }
    
            &.active {
              background-color: #f8c83b;
    
              .icon {
                background-color: #f8c83b;
              }
    
              h6 {
                color: #000;
              }
            }
          }
        }
      }

      .questainers {
        .head {
          display: flex;
          color: #fff;
          background-color: #4c557e;
          padding: 10px;
          align-items: center;
          img {
            margin: 0 8px;
            width: 24px;
          }
        }

        ul {
          background-color: #363e63;
          max-height: 180px;
          min-height: 180px;
          overflow: auto;
          li {
            display: flex;
            align-items: center;
            padding: 10px;
            color: #fff;
            background-color: #363e63;
            border-bottom: 1px solid #293154;
            .left {
              width: 80%;
              font-size: 14px;
            }
            .right {
              text-align: left;
              width: 20%;

              a {
                padding: 0 10px;
                img {
                  width: 18px;
                }
              }
            }
          }
        }
      }
    }
  }

  // Adds and Prints Section
  .adds-prints {
    padding: 50px 0;
    .title {
      h3 {
        color: #000;
        font-size: 25px;
        font-family: "DroidKufi-Bold";
        img {
          margin-left: 10px;
          width: 35px;
        }
      }

      h5 {
        color: #2d2d34;
        font-size: 15px;
      }
    }

    .adds {
      direction: ltr;
      position: relative;

      button {
        &.p-link {
          position: absolute;
          top: 0;
          border: 1px solid #00b188;
          span {
            color: #00b188;
            background: transparent;
            padding: 10px;
            font-weight: bold;
          }

          &.p-carousel-prev {
            left: 0px;
          }
          &.p-carousel-next {
            left: 38px;
          }
        }
      }

      .adds-item {
        .adds-item-content {
          .img {
            width: 100%;
            height: 250px;
            border-radius: 15px;

            img {
              border-radius: 15px;
              width: 100%;
              height: 100%;
              border:1px solid #eee;
            }
          }
        }
      }
    }

    .prints {
      direction: ltr;
      position: relative;
      button {
        &.p-link {
          position: absolute;
          top: 0;
          border: 1px solid #00b188;
          span {
            color: #00b188;
            background: transparent;
            padding: 10px;
            font-weight: bold;
          }

          &.p-carousel-prev {
            left: 0px;
          }
          &.p-carousel-next {
            left: 38px;
          }
        }
      }

      .prints-item {
        position: relative;
        .prints-item-content {
          .img {
            width: 100%;
            height: 250px;
            border-radius: 15px;

            img {
              border-radius: 15px;
              width: 100%;
              height: 100%;
              border:1px solid #eee;
            }
          }
        }

        .overlay {
          height: 100%;
          width: 200px;
          background-image: linear-gradient(rgba(121, 215, 15, 0.6), rgba(6, 98, 59, 0.6));
          position: absolute;
          top: 0;
          right: 20%;
          transform: skewX(10deg);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          h3,
          h4 {
            color: #fff;
            font-size: 24px;
            font-family: "DroidKufi-Bold";
            transform: skewX(-10deg);
            text-align: center;
            margin-bottom: 20px;
          }

          .downloadPdf{
            transform: skewX(-10deg);
            background: #fff;
            padding: 5px 20px;
            border-radius: 5px;

            img{
              width:16px
            }
          }
        }
      }
    }

    .p-carousel-indicators {
      display: none;
    }

    .p-items-hidden .p-carousel-item {
      visibility: visible;
    }
  }

  // Download App Section
  .download-app {
    
    background-size: cover;
    position: relative;
    padding: 50px 0;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to right, #00b188d9 0%, #00b188d1 100%);
    }

    .container {
      text-align: center;
      color: #fff;
      position: relative;

      h3 {
        font-size: 25px;
        font-family: "DroidKufi-Bold";
        margin-bottom: 0px;
      }

      p {
        width: 80%;
        margin: auto !important;
        margin-bottom: 30px !important;
      }

      .divider {
        border: 2px solid #fff;
        position: relative;
        display: inline-block;
        width: 30px;
        margin-top: 30px;
        margin-bottom: 30px;

        &:before {
          position: absolute;
          content: "";
          bottom: -8px;
          right: 0;
          width: 50px;
          height: 1px;
          background: #fff;
        }

        &:after {
          position: absolute;
          content: "";
          top: -8px;
          left: 0;
          width: 50px;
          height: 1px;
          background: #fff;
        }
      }

      .links {
        text-align: center;
        a {
          display: inline-block;
          padding: 10px 20px;
          min-width: 200px;
          border: 2px solid #fff;
          border-radius: 50px;
          text-align: center;
          margin: 0 10px;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          color: #fff;
          text-decoration: none;
          transition: all 0.3s ease-in-out;

          &:hover {
            text-decoration: none;
          }

          img {
            margin: 0 10px;
            width: 24px;
          }
        }
      }
    }
  }

 
}


// Admin Structure Page
.admin-structure {
  .data {
    padding: 50px;
    h3 {
      font-size: 22px;
      color: #000;
      font-family: "DroidKufi-Bold";
    }

    h5 {
      font-size: 18px;
      color: #000;
      margin-bottom: 20px;
      font-family: "DroidKufi-Bold";
    }

    ul {
      margin: 40px 0;
      li {
        color: #000;
        margin-bottom: 5px;
        font-size: 15px;
      }
    }
  }

  .org-chart {
    width: 100%;
    margin: auto;
    padding: 80px;

    .box {
      width: 160px;
      padding: 15px 10px;
      font-size: 14px;
      margin: auto;
      background-color: #eee;
      color: #000;
      font-weight: bold;
      text-align: center;
      border-radius: 8px;
      background: linear-gradient(to right top, rgba(255, 255, 255, 0.5) 6%, rgba(242, 241, 239, 1) 94%);
      border: 1px dashed;
      position: relative;

      span {
        position: absolute;
        display: block;

        &.dashes {
          left: 0;
          width: 100%;
          height: 100%;
          top: 0;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 16px;
            width: 5px;
            height: 20px;
            display: block;
            border-radius: 0 3px 3px 0;
          }

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 16px;
            width: 5px;
            height: 20px;
            display: block;
            border-radius: 3px 0px 0px 3px;
          }
        }

        &.separator {
          left: 0;
          right: 0;
          top: -1px;
          margin: auto;
          width: 40px;
          height: 2px;
          background-color: #fff;
        }
      }
    }

    .top-part {
      margin-bottom: 70px;
      position: relative;
      .levelup {
        text-align: center;
        margin-bottom: 40px;
        position: relative;

        .box {
          border-color: #ff9b50;
          .separator {
            top: auto;
            bottom: -1px;
          }
          .dashes {
            &:before,
            &:after {
              background-color: #ff9b50;
            }
          }
        }
      }

      .levelmiddle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        padding: 0 20px;
        position: relative;
        .box {
          margin: initial;
          border-color: #7702d5;
          .dashes {
            &:before,
            &:after {
              background-color: #7702d5;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: 46%;
          left: calc(100% - 190px);
          margin: auto;
          display: block;
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: 46%;
          right: calc(100% - 190px);
          margin: auto;
          display: block;
        }
      }

      .leveldown {
        text-align: center;
        margin-bottom: 20px;
        position: relative;
        .box {
          border-color: #7702d5;
          .dashes {
            &:before,
            &:after {
              background-color: #7702d5;
            }
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: -18%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #ff5e55;
          top: -129%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 54%;
        background-color: #ff5e55;
        bottom: 24%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }

      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 380px);
        height: 1px;
        background-color: #ff5e55;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }
    }

    .low-part {
      position: relative;
      .levelup {
        margin-bottom: 45px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 75%;
          height: 1px;
          background-color: #6502c9;
          top: -46%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      .leveldown {
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 75%;
          height: 1px;
          background-color: #6502c9;
          top: -46%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      .box {
        position: relative;
        border-color: #34adde;

        .dashes {
          &:before,
          &:after {
            background-color: #34adde;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 35%;
          background-color: #6502c9;
          top: -50%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #6502c9;
          top: -53%;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #6502c9;
        top: -17.5%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 97%;
        background-color: #6502c9;
        top: -48%;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
      }
    }
  }
}

// Directors Board Page
.directors-board {
  .directors-list {
    padding: 80px 0;
    .box {
      width: 100%;
      margin-bottom: 80px;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: 100%;
      height: 85px;
      position: relative;

      img {
        position: absolute;
        right: -20px;
        top: -34px;
        width: 250px;
      }

      .info {
        background-color: #ececee;
        border-radius: 8px;
        height: 100%;
        width: 80%;
        float: left;

        span {
          position: relative;
          font-size: 13px;
          color: #fff;
          width: 150px;
          display: block;
          text-align: center;
          margin-top: -5px;
          line-height: 21px;
          z-index: 2;
        }

        h4 {
          font-size: 12px;
          padding: 0;
          padding-right: 50px;
          font-family: "DroidKufi-bold";
          text-align: center;
          margin-top: 12px;
        }

        p {
          font-size: 11px;
          padding-right: 50px;
          text-align: center;
        }
      }

      &.member {
        .info {
          p {
            color: #ffb84d;
          }
        }
      }

      &.president {
        .info {
          p {
            color: #009f7c;
          }
        }
      }
    }
  }
}

// Laws Page
.laws {
  .content {
    padding: 60px 20px;
    .laws-box {
      width: 85%;
      height: 230px;
      border-radius: 25px;
      position: relative;
      display: flex;
      margin: auto;
      margin-bottom: 30px;
      .img {
        width: 50%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 25px 0 0 25px;
        }
      }

      .info {
        width: 50%;
        background-color: #05555c;
        position: relative;
        border-radius: 0 25px 25px 0;
        padding: 50px 20px;
        h3 {
          color: #fff;
          font-size: 22px;
        }

        button {
          width: 120px;
          padding: 5px 10px;
          background-color: #00bd9f;
          border-radius: 50px;
          position: absolute;
          bottom: 30px;
          right: -50px;
          color: #fff;
        }
      }
    }
  }
}

// News Page
.news {
  .news-grid {
    padding: 25px 0;
    .news-box {
      text-align: center;
      margin-bottom: 45px;
      .main-img {
        width: 100%;
        height: 300px;
        border-radius: 10px;
        cursor: pointer;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 0.5s ease-in-out;
      }

      .meta-data {
        width: 80%;
        background-color: #fff;
        margin: auto;
        padding: 10px 20px;
        background: #fff;
        margin-top: -30px;
        position: relative;
        z-index: 1;
        border-radius: 8px;
        box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.3);

        span {
          padding: 0 10px;
          color: #686a6f;
          font-size: 12px;
          img {
            margin: 0 5px;
          }

          &:first-child {
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          }
        }
      }

      .title {
        font-size: 20px;
        text-align: center;
        padding: 0 20px;
        font-family: "DroidKufi-bold";
        margin-bottom: 25px;
        margin-top: 20px;
        color: #222429;
        transition: all 0.5s ease-in-out;
        min-height: 50px;
      }

      .description {
        font-size: 15px;
        font-family: "DroidKufi-Regular";
        color: #7b848e;
        margin-bottom: 25px !important;
      }

      .readMore {
        text-align: center;
        a {
          display: inline-block;
          width: 60px;
          height: 60px;
          text-align: center;
          border-radius: 50%;
          padding-top: 20px;
          background-color: #d4d4d5;
          cursor: pointer;
          transition: all 0.5s ease-in-out;
          img {
            width: 12px;
          }
        }
      }

      &:hover {
        .title {
          color: #009f7c;
        }
        .main-img {
          -webkit-filter: grayscale(0);
          -moz-filter: grayscale(0);
          -o-filter: grayscale(0);
          filter: grayscale(0);
        }
        .readMore {
          a {
            background-color: #009f7c;
          }
        }
      }
    }
  }
}

// events Page
.events {
  .event-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 35px;
    margin-bottom: 35px;
    border-bottom: 1px solid #e9ebee;
    .left {
      width: 30%;
      position: relative;

      img {
        width: 100%;
        height: 200px;
        max-width: 100%;
      }

      .date {
        width: 120px;
        height: 120px;
        text-align: center;
        line-height: 1.1;
        padding-top: 15px;
        position: absolute;
        background: #fff;
        top: 50px;
        left: -50px;
        box-shadow: 0px 4px 26px 3px rgba(0, 0, 0, 0.1);

        span {
          // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          display: block;
          font-size: 14px;
          &:first-child {
            font-size: 50px;
            color: #009f7c;
            margin-bottom: 10px;
          }
        }
      }
    }

    .right {
      width: 60%;
      
      a{

        &:hover{
          color:unset;
          text-decoration:unset;
        }
      }

      .title {
        font-size: 20px;
        font-family: "DroidKufi-Bold";
        line-height: 1.1;
        color: #222429;
        margin-bottom: 15px;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        min-height: 44px;
        
        &:hover {
          color: #009f7c;
        }
      }

      .meta {
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 10px;

        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 60px;
          height: 3px;
          background-color: #009f7c;
        }

        span {
          font-size: 14px;
          color: #7b848e;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

          &:last-child {
            padding: 0 10px;
            font-family: "DroidKufi-Regular";
            img {
              margin: 0 10px;
            }
          }
        }
      }

      .description {
        font-size: 15px;
        font-family: "DroidKufi-Regular";
        color: #7b848e;
        margin-bottom: 25px;
        width: 90%;
        line-height: 25px;
      }
    }
  }
}

// Competitions Page
.competitions {
  .seasons-sports {
    background-color: #f7f7f5;
    border-bottom: 2px solid #f8c83b;
    padding: 25px 0 40px 0;
    position: relative;

    .seasons {
      direction: ltr;

      button {
        &.p-link {
          // background-color: #707070;
          height: 132px;
          margin: 0;
          margin-top: -16px;
          width: 50px;
          position: relative;
          span {
            color: #fff;
            background: transparent;
            padding: 15px;
            font-weight: bold;
            z-index: 99;
          }

          &.p-carousel-next {
            border-left: 15px solid #707070;
            border-radius: 0;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              border-top: 65px solid transparent;
              border-left: 30px solid #707070;
              border-bottom: 67px solid transparent;
            }
          }

          &.p-carousel-prev {
            border-right: 15px solid #707070;
            border-radius: 0;
            &:before {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              display: block;
              border-top: 65px solid transparent;
              border-right: 30px solid #707070;
              border-bottom: 67px solid transparent;
            }
          }

          &:hover{
            background-color: transparent;
            outline: none;
            box-shadow: none;
          }
        }
      }

      .p-carousel-indicators {
        display: none;
      }

      .p-items-hidden .p-carousel-item {
        visibility: visible;
      }

      .p-carousel-item {
        padding: 0 25px;
        .season-item {
          text-align: center;
          height: 150px;
          width: 260px;
          margin: auto;
          cursor: pointer;
          .date {
            background-color: #009f7c;
            color: #fff;
            font-size: 14px;
            // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            text-align: center;
            padding: 5px 10px;
          }

          .title {
            min-height: 90px;
            display: flex;
            background-color: #c9c9c9;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img{
              height: 70px;
              max-width: 100%;
              border-radius: 5px;
            }
            // h5 {
            //   margin: 0;
            //   font-size: 16px;
            // }
          }

          .traingle-shape {
            position: relative;
            text-align: center;
            padding: 12px;
            height: 30px;
            width: 100%;
            margin-top: -8px;
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 51%;
              background: #c9c9c9;
              transform: skew(0deg, 6deg);
            }
            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 50%;
              background: #c9c9c9;
              transform: skew(0deg, -6deg);
            }
          }

          &.active {
            .traingle-shape {
              &:before,
              &:after {
                background: #f8c83b;
              }
            }
          }
        }
      }
    }

    .sports {
      margin-top: 15px;

      .sport-item {
        background-color: #fff;
        height: 250px;
        width: 150px;
        border-radius: 100px;
        text-align: center;
        padding: 16px 0;
        cursor: pointer;
        box-shadow: 0px 4px 26px 3px rgba(0, 0, 0, 0.1);
        margin: 20px 0;

        .icon {
          background-color: #009f7c;
          width: 120px;
          margin: auto;
          height: 120px;
          border-radius: 50%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 70px;
            max-width: 100%;
          }
        }

        h6 {
          margin-top: 30px;
          color: #009f7c;
          font-size: 15px;
          padding: 0 5px;
        }

        &.active {
          background-color: #f8c83b;

          // .icon {
          //   background-color: #f8c83b;
          // }

          h6 {
            color: #000;
            font-family: 'DroidKufi-Bold';
          }
        }
      }
    }

    .selectedSport {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: block;
      bottom: -18px;
      background-color: #fff;
      border-radius: 40px;
      border: 1px solid #f8c83b;
      text-align: center;
      width: 300px;
      padding: 5px 10px;
      span {
        padding: 0 5px;
        font-size: 14px;
        color: #000;
        font-family: 'DroidKufi-Bold';
        &:last-child {
          color: #009f7c;
          position: relative;
          padding: 0 10px;
          &:before {
            content: "";
            position: absolute;
            right: 0;
            top: 12px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            display: block;
            background-color: #009f7c;
          }
        }
      }
    }
  }
  .season-details {
    padding: 40px 0;
    min-height: 500px;

    .container {
      @media screen and (min-width: 1200px) {
        max-width: 1300px;
      }

      .col-md-3 {
        padding: 0;
      }
    }

    .side {
      .latest-news {
        background-color: #f7f7f5;
        padding: 30px 20px;

        .title {
          color: #009f7c;
          text-align: center;
          font-size: 18px;
          margin-bottom: 20px;
          font-family: "DroidKufi-Bold";
        }

        .item {
          margin-bottom: 15px;
          display: flex;

          h3 {
            color: #000;
            font-size: 14px;
            width: calc(100% - 100px);
            padding-left: 10px;
            line-height: 20px;
          }

          img {
            width: 100px;
            height: 70px;
            border-radius: 12px;
          }
        }
      }

      .unversities {
        margin-top: 40px;
        .title {
          background-color: #009f7c;
          padding: 10px 20px;
          color: #fff;
          border-radius: 8px 8px 0 0;
          .badge {
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            margin: 0 10px;
            background: #fff;
            color: #11705c;
            padding-top: 9px;
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          }
        }

        > ul {
          height: 420px;
          overflow: auto;
          li {
            .setName {
              background-color: #11705c;
              text-align: center;
              padding: 10px;
              color: #fff;
            }

            ul {
              li {
                background-color: #f7f7f5;
                border-bottom: 1px solid #dee2e6;
                padding: 8px;
                img {
                  margin: 0 30px;
                  width: 55px;
                }
              }
            }
          }
        }
      }

      .excellence {
        margin-top: 40px;
        margin-bottom: 30px;
        height: 300px;
        border-radius: 8px;
        background-color: #9c612e;
        background-image: linear-gradient(to bottom, #2E4995, #8256CC 50%, #D162FF);
        padding: 20px;

        &.gold {
          background-color: #c7a648;
          background-image: linear-gradient(to bottom, #c7a648, #fdf3ca 50%, #c7a648);
        }
        &.silver {
          background-color: #c6c6c6;
          background-image: linear-gradient(to bottom, #a9a7a7, #f3f3f3 50%, #a9a7a7);
        }
        &.bronze {
          background-color: #9c612e;
          background-image: linear-gradient(to bottom, #9c612e, #fcc593 50%, #9c612e);
        }

        .title {
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          padding-bottom: 10px;
          h3 {
            color: #fff;
            font-size: 22px;
            img {
              margin-left: 10px;
              width: 35px;
            }
          }

          h5 {
            color: #fff;
            font-size: 12px;
          }
        }

        .box-body {
          margin-top: 30px;

          .box {
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            border-radius: 12px;
            background-color: rgba(0, 0, 0, 0.1);
            padding: 20px;
            span {
              color: #fff;
              font-size: 20px;
              font-family: "DroidKufi-Bold";
            }
            img {
              width: 100px;
              max-width: 100%;
              margin: 0 auto;
              margin-bottom: 15px;
            }
          }
        }

        .grades {
          margin-top: 15px;
          text-align: center;
          span {
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            margin: 0 10px;
            background: #fff;
            color: #000;
            padding-top: 3px;
            font-weight: bold;
            font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            cursor: pointer;
            &.gold {
              background-color: #c7a648;
              background-image: linear-gradient(to bottom, #c7a648, #fdf3ca 50%, #c7a648);
            }
            &.silver {
              background-color: #c6c6c6;
              background-image: linear-gradient(to bottom, #c6c6c6, #f1f1f1 50%, #c6c6c6);
            }
            &.bronze {
              background-color: #9c612e;
              background-image: linear-gradient(to bottom, #9c612e, #fcc593 50%, #9c612e);
            }
          }
        }
      }
    }

    .media-center {
      padding: 20px 30px 0px;
      .title {
        display: flex;
        h3 {
          width: 25%;
          color: #009f7c;
          font-size: 20px;
          font-family: "DroidKufi-Bold";
        }

        .tabs {
          padding: 0 25px;
          .tabs-header {
            text-align: center;
            a {
              color: #aaaaaa;
              font-size: 22px;
              text-decoration: none;
              padding: 0 0 10px 0;
              display: inline-block;
              margin: 0 20px;
              margin-bottom: -3px;
              font-size: 16px;

              &.active {
                color: #f8c83b;
                border-bottom: 4px solid #f8c83b;
              }
            }
          }
        }
      }

      .tab-content {
        margin: 20px 0 0;

        .media-box {
          margin: 15px 0;
          position: relative;
          height: 200px;
          cursor: pointer;
          border-radius: 12px;
          border: 1px solid #707070;
          overflow: hidden;
          img.main-img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            transition: all 0.5s ease-in-out;

            &:hover {
              transform: scale(1.2);
            }
          }

          .play-img {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 50px;
            z-index: 99;
            width: 100px;
            opacity: 0.8;
          }
        }
        .file-box {
          margin: 15px 0;
          position: relative;
          height: 220px;
          cursor: pointer;
          border-radius: 12px;
          border: 1px solid #eee;
          overflow: hidden;
          a {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 99;
            background: #009f7c;
            padding: 10px;
            border-radius: 0 5px;

            img {
              width: 22px;
            }
          }
          img.main-img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            transition: all 0.5s ease-in-out;
          }

          .info {
            position: absolute;
            bottom: -100px;
            left: 0;
            width: 100%;
            padding: 20px;
            background-color: rgba(0, 159, 124, 0.9);
            h3 {
              font-size: 18px;
              color: #fff;
            }

            p {
              margin: 0;
              font-size: 14px;
              color: #fff;
            }
          }

          &:hover {
            .info {
              bottom: 0;
            }
          }
        }
      }
    }

    .matches {
      padding: 20px 30px 0;
      .title {
        display: flex;
        h3 {
          width: 25%;
          color: #009f7c;
          font-size: 20px;
          font-family: "DroidKufi-Bold";
        }

        .tabs {
          padding: 0 25px;
          .tabs-header {
            text-align: center;
            a {
              color: #aaaaaa;
              font-size: 22px;
              text-decoration: none;
              padding: 0 0 10px 0;
              display: inline-block;
              margin: 0 20px;
              margin-bottom: -3px;
              font-size: 16px;

              &.active {
                color: #f8c83b;
                border-bottom: 4px solid #f8c83b;
              }
            }
          }
        }
      }

      .tab-content {
        margin: 20px 0 0;

        ul {
          width: 100%;
          margin: 15px auto;
          li {
            margin-bottom: 15px;
            .box-head {
              border-radius: 20px;
              display: flex;
              position: relative;
              color: #000;
              text-align: center;
              font-family: "DroidKufi-Bold";
              border: 2px solid #eee;
              img {
                margin: 0 10px;
                width: 50px;
                position: absolute;
                right: 5%;
              }

              .left {
                background-color: #00b188;
                padding: 6px 15px;
                border-radius: 0 25px 25px 0;
                font-size: 12px;
                width: 50%;
                font-family: "DroidKufi-Bold";
                position: relative;
              }

              .right {
                background-color: #f6c52d;
                padding: 6px 15px;
                border-radius: 25px 0 0 25px;
                font-size: 12px;
                width: 50%;
                font-family: "DroidKufi-Bold";
                position: relative;
                img {
                  left: 5%;
                  right: auto;
                }
              }

              .vs {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                display: inline-block;
                background-color: #bbb6a5;
                border: 1px solid #e4e4e4;
                width: 32px;
                height: 32px;
                padding-top: 2px;
                border-radius: 50%;
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                font-weight: bold;
                z-index: 9;
              }
            }

            .box-body {
              background-color: #fff;
              border-radius: 0 0 10px 10px;
              width: 95%;
              margin: auto;
              display: flex;
              padding: 0;
              box-shadow: 0px 0px 5px 2px #b5b5b5;

              .left {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                font-weight: bold;
                color: #000;
                position: relative;
                img {
                  position: absolute;
                  right: 5%;
                  margin: 0 8px;
                }
              }

              .right {
                width: 40%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                span {
                  padding: 0 20px;
                  text-align: center;
                  strong {
                    display: block;
                    color: #181743;
                    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                  }

                  small {
                    font-size: 12px;
                    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                  }
                }
              }

              .middle {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                font-size: 22px;
                color: #000;
              }
            }
          }
        }
      }
    }

    .gathering-prints {
      padding: 20px 30px 0;
      .title {
        h3 {
          color: #000;
          font-size: 25px;
          font-family: "DroidKufi-Bold";
          img {
            margin-left: 10px;
            width: 35px;
          }
        }

        h5 {
          color: #2d2d34;
          font-size: 15px;
        }
      }

      .prints {
        direction: ltr;
        position: relative;
        button {
          &.p-link {
            position: absolute;
            top: 0;
            border: 1px solid #00b188;
            span {
              color: #00b188;
              background: transparent;
              padding: 10px;
              font-weight: bold;
            }

            &.p-carousel-prev {
              left: 0px;
            }
            &.p-carousel-next {
              left: 38px;
            }
          }
        }

        .prints-item {
          position: relative;
          .prints-item-content {
            .img {
              width: 100%;
              height: 215px;
              border-radius: 15px;

              img {
                border-radius: 15px;
                width: 100%;
                height: 100%;
              }
            }
          }

          .overlay {
            height: 100%;
            width: 200px;
            background-image: linear-gradient(rgba(121, 215, 15, 0.6), rgba(6, 98, 59, 0.6));
            position: absolute;
            top: 0;
            right: 20%;
            transform: skewX(10deg);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3,
            h4 {
              color: #fff;
              font-size: 24px;
              font-family: "DroidKufi-Bold";
              transform: skewX(-10deg);
              text-align: center;
              margin-bottom: 20px;
            }
  
            .downloadPdf{
              transform: skewX(-10deg);
              background: #fff;
              padding: 5px 20px;
              border-radius: 5px;
  
              img{
                width:16px
              }
            }
          }
        }

        .p-carousel-indicators {
          display: none;
        }
      }

      .gathering {
        .info {
          margin-top: 40px;
          .top {
            background-color: #009f7c;
            font-size: 30px;
            color: #fff;
            border-radius: 50px 0 0 0;
            height: 105px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              display: block;
              bottom: -15px;
              width: 30px;
              height: 10px;
              border-top: solid 15px #009f7c;
              border-left: solid 15px transparent;
              border-right: solid 15px transparent;
            }
          }

          .bottom {
            border-radius: 0 0 50px 50px;
            border: 1px solid #009f7c;
            display: flex;
            height: 110px;
            .left,
            .right {
              width: 50%;
              text-align: center;
              padding-top: 30px;
              color: #009f7c;

              p {
                &:last-child {
                  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                }
              }
            }

            .left {
              border-left: 1px solid #009f7c;
            }
          }
        }
      }
    }
  }
}

// Details Page
.details-page {
  .content {
    text-align: center;
    .main-img {
      margin-bottom: 20px;
      img {
        border-radius: 15px;
        cursor: pointer;
        width: 90%;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 0.5s ease-in-out;

        &:hover {
          -webkit-filter: grayscale(0);
          -moz-filter: grayscale(0);
          -o-filter: grayscale(0);
          filter: grayscale(0);
        }
      }
    }

    .meta-data {
      width: 300px;
      text-align: center;
      background-color: #fff;
      margin: auto;
      padding: 10px 20px;
      background: #fff;
      margin-top: -42px;
      position: relative;
      z-index: 1;
      border-radius: 8px;
      box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.3);

      span {
        padding: 0 10px;
        color: #686a6f;
        font-size: 12px;
        img {
          margin: 0 5px;
        }

        &:first-child {
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        }
      }
    }

    .title {
      h3 {
        font-size: 20px;
        font-family: "DroidKufi-Bold";
        line-height: 1.1;
        color: #009f7c;
        margin: 25px auto;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        width: 90%;
      }
    }

    .description {
      border: 1px solid #eee;
      padding: 20px;
      border-radius: 15px;
      box-shadow: 0px 0px 5px #eee;
      width: 90%;
      margin: auto;
      
      background-repeat: no-repeat;
      background-size: 200px;
      background-position: center center;
      p {
        font-size: 15px;
        font-family: "DroidKufi-Regular";
        color: #6c757d;
        margin: auto;
        margin-bottom: 25px;
        line-height: 28px;
        text-align: justify;
      }
    }
  }
}

.post-author {
  text-align: right;
  width: 90%;
  margin: 15px auto;
  a {
    background-color: #009f7c;
    border: 1px solid #009f7c;
    padding: 15px 25px;
    min-width: 200px;
    color: #fff;
    display: inline-block;
    border-radius: 10px;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      text-decoration: none;
      background-color: #fff;
      color: #009f7c;
    }
  }
}

// Technical Support
.technical-support {
  .content {
    background-color: #f7f7f5;
    padding: 25px 20px;
    min-height:424px;
    
    .form {
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 12px;
      box-shadow: 0px 0px 8px 0 #d4d2d2;
      padding: 20px 25px;
      position: relative;

      img {
        position: absolute;
        left: 25px;
        top: -40px;
        width: 100px;
        box-shadow: 0px 0px 8px 0 #adb5bd;
        background: #fff;
        padding: 10px;
        border-radius: 10px;
      }

      h3 {
        color: #009f7c;
        font-family: "DroidKufi-Bold";
        font-size: 18px;
        margin-bottom: 20px;
      }

      .fields {
        label {
          font-size: 14px;
          margin-bottom: 2px;
          color: #707070;
        }

        textarea {
          height: 120px;
          resize: none;
        }

        .btn{
          background-color: #009f7c;
          border: 1px solid #009f7c;
          padding: 15px 25px;
          min-width: 200px;
          color: #fff;
          display: inline-block;
          border-radius: 10px;
          text-align: center;
          transition: all 0.3s ease-in-out;
          // margin-top: 20px;
          &:hover {
            background-color: #fff;
            color: #009f7c;
          }
        }
      }
    }
  }
}

// FAQ page
.faq {
  .content {
    background-color: #f7f7f5;
    padding: 60px 50px;

    .questions {
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 12px;
      box-shadow: 0px 0px 8px 0 #d4d2d2;
      padding: 60px 25px;
      position: relative;

      img {
        position: absolute;
        left: 25px;
        top: -40px;
        width: 100px;
        box-shadow: 0px 0px 8px 0 #adb5bd;
        background: #fff;
        padding: 10px;
        border-radius: 10px;
      }

      .item {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 2px solid #dee2e6;
        &:last-child {
          border-bottom: 0;
        }

        h3 {
          color: #009f7c;
          font-size: 16px;
          font-family: "DroidKufi-Bold";
          margin-bottom: 20px;
        }

        .p-accordion-tab {
          margin-bottom: 10px;
          .p-accordion-header {
            background: #ebebeb;
            padding: 15px;
            border-radius: 5px 5px 0 0;

            .p-accordion-header-link {
              color: #222429;
              text-decoration: none;

              .p-accordion-toggle-icon {
                color: #009f7c;
                padding-left: 15px;
              }
            }
          }

          .p-toggleable-content {
            padding: 10px;
            border: 1px solid #eee;
            border-radius: 0 0 10px 10px;
            transform: initial;
          }
        }
      }
    }
  }
}

// Terms and Condition
.terms-conditions {
  .content {
    background-color: #f7f7f5;
    padding: 60px 50px;

    .data {
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 12px;
      box-shadow: 0px 0px 8px 0 #d4d2d2;
      padding: 60px 25px;
      position: relative;

      img {
        position: absolute;
        left: 25px;
        top: -40px;
        width: 100px;
        box-shadow: 0px 0px 8px 0 #adb5bd;
        background: #fff;
        padding: 10px;
        border-radius: 10px;
      }

      .item {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 2px solid #dee2e6;
        &:last-child {
          border-bottom: 0;
        }

        h3 {
          color: #009f7c;
          font-size: 16px;
          font-family: "DroidKufi-Bold";
          margin-bottom: 20px;
        }

        p {
          font-size: 15px;
          font-family: "DroidKufi-Regular";
          color: #7b848e;
        }
      }
    }
  }
}

// Videos Page
.videos-page {
  .content {
    margin: 20px 0 0;
    .media-box {
      margin: 15px 0;
      position: relative;
      height: 220px;
      cursor: pointer;
      border-radius: 12px;
      border: 1px solid #707070;
      overflow: hidden;
      img.main-img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        transition: all 0.5s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }

      .play-img {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 70px;
        z-index: 99;
        width: 100px;
        opacity: 0.5;
      }
    }
  }
}

// Photo Page
.photos-page {
  .grid {
    column-count: 4;
    column-gap: 0.5em;
    max-width: 980px;
    margin: auto;
    width: 100%;
    img {
      height: auto;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1601px) {
    .grid {
      column-count: 6;
      max-width: 100%;
    }
  }

  @media (min-width: 1400px) {
    .grid {
      column-count: 5;
      max-width: 100%;
    }
  }

  @media (max-width: 800px) {
    .grid {
      column-count: 3;
    }
  }

  @media (max-width: 600px) {
    .grid {
      column-count: 2;
    }
  }

  @media (max-width: 400px) {
    .grid {
      column-count: 1;
    }
  }
}

// Result Page
.result-page {
  .results-table {
    padding: 30px;
    p {
      font-size: 18px;
      text-align: center;
    }
  }
}

// Advertisments
.advertisments {
  .content {
    margin: 20px 0 0;
    .advertice-box {
      margin: 15px 0;
      position: relative;
      height: 220px;
      cursor: pointer;
      border-radius: 12px;
      border: 1px solid #eee;
      overflow: hidden;

      a {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
        background: #009f7c;
        padding: 10px;
        border-radius: 0 5px;

        img {
          width: 22px;
        }
      }
      img.main-img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        transition: all 0.5s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

// Media File
.mediaFile {
  .content {
    margin: 20px 0 0;
    .file-box {
      margin: 15px 0;
      position: relative;
      height: 220px;
      cursor: pointer;
      border-radius: 12px;
      border: 1px solid #eee;
      overflow: hidden;
      a {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
        background: #009f7c;
        padding: 10px;
        border-radius: 0 5px;

        img {
          width: 22px;
        }
      }
      img.main-img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        transition: all 0.5s ease-in-out;
      }

      .info {
        position: absolute;
        bottom: -100px;
        left: 0;
        width: 100%;
        padding: 20px;
        background-color: rgba(0, 159, 124, 0.9);
       
        a{
          position: unset;
          right: unset;
          top: unset;
          z-index: unset;
          background: unset;
          padding: unset;
          border-radius: unset;
          color: unset;
          text-decoration:unset;    
            h3 {
            font-size: 18px;
            color: #fff;
          }
        }

        p {
          margin: 0;
          font-size: 14px;
          color: #fff;
        }
      }

      &:hover {
        .info {
          bottom: 0;
        }
      }
    }
  }
}

// Prints Page
.prints {
  .content {
    padding: 60px 20px;
    .p-accordion-tab {
      margin-bottom: 10px;
      .p-accordion-header {
        background: #ebebeb;
        padding: 15px;
        border-radius: 5px 5px 0 0;

        .p-accordion-header-link {
          color: #222429;
          text-decoration: none;

          .p-accordion-toggle-icon {
            color: #009f7c;
            padding-left: 15px;
          }
        }
      }

      .p-toggleable-content {
        padding: 30px;
        border: 1px solid #eee;
        border-radius: 0 0 10px 10px;
        transform: initial;
      }
    }
    .print-box {
      width: 85%;
      height: 230px;
      border-radius: 25px;
      position: relative;
      display: flex;
      margin: auto;
      margin-bottom: 30px;
      .img {
        width: 50%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 25px 0 0 25px;
        }
      }

      .info {
        width: 50%;
        background-color: #05555c;
        position: relative;
        border-radius: 0 25px 25px 0;
        padding: 50px 20px;
        h3 {
          color: #fff;
          font-size: 20px;
        }

        button {
          width: 120px;
          padding: 5px 10px;
          background-color: #00bd9f;
          border-radius: 50px;
          position: absolute;
          bottom: 30px;
          right: -50px;
          color: #fff;
        }
      }
    }
  }
}

/*********Arabic Version*****************/
@import "./main-ar.scss";

@import "./rtl.scss";
html[dir="rtl"] {
  // General Style
  ul {
    padding: 0;
  }

  .main-footer {
    .social {
      a {
        padding-left: 20px;
        padding-right: 0px;
      }
    }

    .links {
      ul {
        float: right;
        margin-left: 25px;
        margin-right: 0;
      }
    }

    .closeBTN {
      margin-left: 0;
      margin-right: 25px;
    }
  }

  // President Word Section
  .presidentWord {
    .container {
      .left {
        &:before {
          left: auto;
          right: -35px;
        }
        &:after {
          left: auto;
          right: -66px;
        }
      }

      .right {
        &:before {
          left: -35px;
          right: auto;
        }
        &:after {
          left: -66px;
          right: auto;
        }
      }
    }
  }

  // Statistics Section
  .statistics {
    .statistics-left {
      .box {
        float: right;
      }
    }
  }
}

// Responsive



@media screen and (min-width: 1400px) {
  .homepage{
    .electronicServices{
      background-size: 100% auto, auto, 200px, auto, auto, auto;
      .container{
        max-width: 1300px;
        .services{
          .service-box{
            height: 200px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1650px) {
  .homepage{
    .electronicServices{
      .container{
        max-width: 1600px;
        .services{
          .service-box{
            height: 280px;
            font-size: 20px;
            img{
              width: 45%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-header {
    .header-items {
      .container {
        .navbar {
          .navbar-brand {
            img {
              width: 150px;
            }
          }
        }
      }
    }
  }

  .homepage {
    .electronicServices {
      .main-title {
        font-size: 30px;
      }
    }

    .presidentWord {
      overflow: hidden;
      .container {
        .left:after,
        .right:after {
          width: 350px;
        }
      }
    }

    .newsticker {
      .p-carousel {
        width: 100%;
        .p-carousel-content {
          white-space: nowrap;
          unicode-bidi: plaintext;
          text-overflow: ellipsis;
        }
      }
    }

    .media-center{
      margin-top: 0;
    }

  }
}

@media screen and (max-width: 991px) {
  .main-header {
    .header-items {
      .container {
        .navbar {
          .navbar-nav {
            padding-top: 0px;
          }
          .navbar-toggle {
            display: block;
          }
          .navbar-collapse {
            background-color: #474748;
            z-index: 999;
          }  
        }        
      }
    }
  }

  .homepage {
    .mainSLider {
      .slider-wrapper {
        .inner {
          display: block;
          .left {
            margin: 0;
            text-align: center;
            width: 100%;
          }

          .right {
            display: none;
          }
        }
      }
    }

    .electronicServices {
      background-position: 100% 100%, -30% -10%, 89px 100%, 101.8% 25%, -1.8% 90%, 115% 120%;
      .col-md-12.col-lg-4 {
        text-align: center;

        h3 {
          margin-top: 0;
          margin-bottom: 30px;
        }
      }
    }

    .presidentWord {
      overflow: hidden;
      .container {
        .left:after,
        .right:after {
          width: 200px;
        }
      }
    }
  }

  .admin-structure{
    .org-chart{
      padding: 80px 40px;
    }
  } 

  .page-with-sidebar{
    flex-direction: column-reverse;
    .sidebar{
      width: 100%;

      .latestnews,
      .latest-matches,
      .adds{  
        width:50%;
        margin: 0 auto 20px;
      }
    }

    .content {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .main-header {
    .fixed{
      .topbar{
        display: none;
      }

      .container{
        .navbar{
          .navbar-brand{
            img{
              width:120px 
            }
          }
        } 
      } 
        
      
    }
    .topbar {
      display: block;

      .left {
        ul {
          justify-content: center;
          li{
            border: none;
          }
        }
      }

      .right {
        width: 100%;
        ul {
          justify-content: center;
          li {
            padding: 5px 15px;
            border: none;
          }
        }
      }
    }

    &.homeHeader{
      .header-items {
        top: 69px;
      }

      .fixed{
        .header-items{
          top: 0;
        }
      }
    }

    .header-items {
      .container {
        .navbar {
          .navbar-nav {
            background-size: 100px;
          } 
        }        
      }
    }
  }

  .homepage {
    .newsticker {
      display: none;
    }

    .mainSLider {
      .slider-wrapper {
        .inner {
          .left {
            padding: 5% 15%;
            .description {
              font-size: 13px;
            }
          }
        }
      }
    }

    .electronicServices {
      background-position: 100% 100%, -350% -10%, 89px 100%, 104% 25%, -1.8% 90%, 115% 122%;
      padding: 70px 0;
      height: auto;
      .services .service-box {
        width: 45%;
      }
    }

    .presidentWord {
      .container {
        display: block;
        text-align: center;

        .left {
          margin-bottom: 50px;
        }

        .right {
          h3 {
            margin-bottom: 15px;
          }

          p {
            font-size: 18px;
          }
        }

        .right:after,
        .right:before,
        .left:after,
        .left:before {
          display: none;
        }
      }
    }

    .about-union {
      height: 630px;
      .playButton{
        text-align: center;
      }
    }

    .media-center {
      margin-top: 0;
      background-image: none;
      .title {
        margin-bottom: 50px;
        text-align: center;
      }
      .tabs {
        .tabs-header {
          a {
            margin: 0 10px;

            &.active {
              margin-bottom: -3px;
            }
          }
        }
      }
    }

    .events-competitions {
      .container {
        padding: 30px 15px;
      }

      .title {
        h3 {
          margin-bottom: 20px;
        }
      }

      .competitions {
        .comps {
          .sports .sport-item{
            margin: 0 3px;
          }
        }

        .questainers {
          ul {
            li {
              .right a {
                padding: 0 5px;
              }
            }
          }
        }
      }
    }

    .adds-prints {
      .title {
        h3 {
          margin-bottom: 20px;
        }
      }

      .adds {
        margin-bottom: 25px;
      }
    }

    .statistics {
      background-image: none;
      .statistics-left {
        .box {
          width: 45%;
          margin: 15px 2.5%;
        }
      }
      .union {
        margin-bottom: 20px;
      }

      .statistics-right {
        zoom: 0.8;
      }
    }

    .honor-board {
      h3 {
        font-size: 25px;
      }
    }

    .download-app{
      .container{
        .links{
          a{
            margin-bottom:  10px;
          }
        } 
      } 
    } 
  }

  .about-union {
    .grid-left-right {
      .item {
        display: block;
        margin-bottom: 5px;
        .left,
        .right {
          width: 100%;
        }

        &.second{
          display: flex;
          flex-direction: column-reverse
        }
      }
    }
  }

  .main-footer {
    .links {
      ul {
        width: 50%;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    .topfooter {
      text-align: center;
      .contacts {
        margin: 20px 0;
      }
    }
  }

  .banner {
    .overlay {
      h3 {
        font-size: 50px;
      }
    }
  }

  .admin-structure{
    .org-chart{
      padding: 80px 15px;
      zoom: 0.5;
    }
  } 

  .page-with-sidebar{
    .sidebar{
      .latestnews,
      .latest-matches,
      .adds{  
        width:100%;
        margin: 0 auto 20px;
      }
    }
  }

  .competitions{
    .season-details{
      .container{
        .row{
          flex-direction: column-reverse;

          .side{
            margin-top: 50px;
            padding: 0 10px;
          }

          .media-center{
            .title{
              flex-direction: column;
              h3{
                width: 100%;
                text-align: center;
                margin-bottom: 15px;
              }

              .tabs{
                width: 100%;
                padding: 0;

                .tabs-header{
                  a{
                    margin: 0 10px;
                  }
                }
              }
            }
          }

          .matches{
            .title{
              flex-direction: column;
              h3{
                width: 100%;
                text-align: center;
                margin-bottom: 15px;
              }

              .tabs{
                width: 100%;
                padding: 0;

                .tabs-header{
                  a{
                    margin: 0 10px;
                    margin-bottom: 15px;
                  }
                }
              }
            }
          }

          .prints{
            margin-top: 25px;
          }
        }
      }
    }
  }
}

@media screen and (max-width:475px){
  .homepage {
    .electronicServices {
      background-position: 100% 100%, -350% -10%, 89px 100%, 106.8% 25%, -7.8% 90%, 150% 124%;
    }
  }
}

@media screen and (max-width: 400px) {
  .admin-structure{
    .org-chart{
      padding: 80px 15px;
      zoom: 0.4;
    }
  }
}